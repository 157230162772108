import React, { useState, useCallback, useMemo } from 'react';
import { ThemeProvider, createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { Container, Grid, Box, Typography } from '@mui/material';
import { EditionProvider } from './context/EditionContext';
import Header from './components/Header';
import BiasSlider from './components/BiasSlider';
import FlashOfTheDay from './components/FlashOfTheDay';
import NewsColumn from './components/NewsColumn';
import HeadlinesColumn from './components/HeadlinesColumn';
import Footer from './components/Footer';
import AdBlockDetector from './components/AdBlockDetector';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: prefersDarkMode ? '#90caf9' : '#1976d2',
          },
          secondary: {
            main: prefersDarkMode ? '#f48fb1' : '#dc004e',
          },
          background: {
            default: prefersDarkMode ? '#121212' : '#f5f5f5',
            paper: prefersDarkMode ? '#1e1e1e' : '#ffffff',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h1: {
            fontWeight: 700,
            letterSpacing: '-0.01562em',
          },
          h2: {
            fontWeight: 600,
            letterSpacing: '-0.00833em',
          },
          h3: {
            fontWeight: 600,
            letterSpacing: '0em',
          },
          body1: {
            letterSpacing: '0.00938em',
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 16,
                boxShadow: prefersDarkMode 
                  ? '0 8px 16px rgba(255,255,255,0.1)' 
                  : '0 8px 16px rgba(0,0,0,0.1)',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: prefersDarkMode 
                    ? '0 12px 24px rgba(255,255,255,0.15)' 
                    : '0 12px 24px rgba(0,0,0,0.15)',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                textTransform: 'none',
                fontWeight: 600,
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  const [bias, setBias] = useState(50);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleBiasChange = useCallback((newValue) => {
    setBias(newValue);
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  const handleResetBias = useCallback(() => {
    setBias(50);
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  return (
    <EditionProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl" sx={{ 
          py: { xs: 2, md: 4 },
          px: { xs: 1, sm: 2, md: 3 }
        }}>
          <Box
            sx={{
              maxWidth: 'md',
              mx: 'auto',
              width: '100%',
            }}
          >
            <Box sx={{ width: '89%', mx: 'auto' }}>
              <Header />
            </Box>
            
            <Box sx={{ my: 4 }} />
            
            <FlashOfTheDay />
          </Box>
          
          <Box sx={{ my: 6 }} />
          
          <BiasSlider value={bias} onChange={handleBiasChange} />
          
          <Box sx={{ my: 4 }} />
          
          <HeadlinesColumn refreshKey={refreshKey} bias={bias} />
          
          <Box sx={{ my: 8 }} />
          
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={{ xs: 2, md: 4 }}>
                <Grid item xs={12} md={6}>
                  <NewsColumn category="politics" bias={bias} refreshKey={refreshKey} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NewsColumn category="business-tech" bias={bias} refreshKey={refreshKey} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <NewsColumn category="entertainment-sports" bias={bias} refreshKey={refreshKey} />
            </Grid>
          </Grid>
          
          <Footer />
        </Container>
        <AdBlockDetector />
      </ThemeProvider>
    </EditionProvider>
  );
}
export default App;

