import React, { useState, useEffect } from 'react';
import { Typography, Box, Link, CardMedia, Card, CardContent, CircularProgress } from '@mui/material';
import { getFlashOfTheDay } from '../utils/flashOfTheDay';
import LoadingSpinner from './common/LoadingSpinner';
import { useEdition } from '../context/EditionContext';

const FlashOfTheDay = () => {
  const { currentEdition } = useEdition();
  const [flashArticle, setFlashArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFlashOfTheDay = async () => {
      try {
        const article = await getFlashOfTheDay(currentEdition);
        if (article) {
          setFlashArticle(article);
        } else {
          setError('Unable to fetch Flash of the Day. Please try again later.');
        }
      } catch (err) {
        console.error('Error fetching Flash of the Day:', err);
        setError('Failed to fetch Flash of the Day. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchFlashOfTheDay();
  }, [currentEdition]);

  if (loading) return <LoadingSpinner />;

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        marginTop: { xs: '64px', md: '80px' }
      }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!flashArticle) return null;

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      px: { xs: 2, md: 3 },
      marginTop: { xs: '96px', md: '120px' }
    }}>
      <Card sx={{ 
        mb: { xs: 2, md: 4 },
        width: '100%',
        maxWidth: '800px',
        overflow: 'hidden',
      }}>
        <CardContent sx={{ p: { xs: 1.5, sm: 2 } }}>
          <Typography 
            variant="h5" 
            component="div" 
            gutterBottom 
            align="center" 
            sx={{ 
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'primary.main',
              fontSize: { xs: '1.25rem', sm: '1.5rem' }
            }}
          >
            Flash of the Day
          </Typography>
          {flashArticle.urlToImage && (
            <Box sx={{ position: 'relative', paddingTop: '56.25%', mb: 2 }}>
              <CardMedia
                component="img"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                image={flashArticle.urlToImage}
                alt={flashArticle.title}
              />
            </Box>
          )}
          <Typography variant="caption" color="text.secondary" display="block" align="right" sx={{ mb: 2 }}>
            Source: {flashArticle.source.name}
          </Typography>
          <Link 
            href={flashArticle.url} 
            target="_blank" 
            rel="noopener noreferrer"
            underline="none"
            color="inherit"
          >
            <Typography 
              variant="h6" 
              component="div" 
              gutterBottom 
              sx={{ 
                fontWeight: 'bold',
                transition: 'color 0.3s ease-in-out',
                '&:hover': {
                  color: 'primary.main',
                }
              }}
            >
              {flashArticle.title}
            </Typography>
          </Link>
          <Typography variant="body1" color="text.secondary">
            {flashArticle.description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FlashOfTheDay;
