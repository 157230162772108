import * as usEdition from './news-sources-us';
import * as euEdition from './news-sources-eu';

export const EDITION_SOURCES = {
  us: usEdition,
  eu: euEdition
};

export function getSourcesForEdition(editionId) {
  const sources = EDITION_SOURCES[editionId] || EDITION_SOURCES.us;
  console.log(`Sources for ${editionId}:`, {
    biasSourcesCount: sources.BIAS_SOURCES?.length || 0,
    databaseCount: Object.keys(sources.BIAS_DATABASE || {}).length,
    rssFeedsCount: sources.RSS_FEEDS?.length || 0
  });
  return sources;
} 