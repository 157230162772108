// US Edition News Sources

// Define sources once in their respective groups
export const FAR_LEFT = [
  'motherjones.com', 'thenation.com', 'alternet.org', 'democracynow.org',
  'truthdig.com', 'inthesetimes.com', 'progressive.org', 'huffpost.com', 
  'msnbc.com', 'vox.com', 'slate.com', 'thedailybeast.com',
  'theintercept.com', 'rawstory.com', 'commondreams.org', 'truthout.org',
  'salon.com', 'talkingpointsmemo.com', 'newrepublic.com', 'prospect.org',
  'currentaffairs.org', 'jacobin.com', 'peoplesworld.org',
  'counterpunch.org', 'fair.org', 'inequality.org', 'leftvoice.org',
  'socialistworker.org', 'workers.org', 'wsws.org',
  'blackagendareport.com', 'socialistalternative.org', 'labornotes.org',
  'portside.org', 'socialistproject.org', 'workingclass.org',
  'dissidentvoice.org', 'occupy.com', 'revolutionobserver.com',
  'liberationnews.org', 'popularresistance.org'
];

export const CENTER_LEFT = [
  'nytimes.com', 'washingtonpost.com', 'cnn.com', 'politico.com',
  'theatlantic.com', 'newyorker.com', 'time.com', 'newsweek.com',
  'latimes.com', 'vice.com', 'buzzfeednews.com', 'nbcnews.com',
  'abcnews.go.com', 'cbsnews.com', 'propublica.org', 'fivethirtyeight.com',
  'theweek.com', 'rollingstone.com', 'vanityfair.com', 'theroot.com',
  'theverge.com', 'mashable.com', 'fastcompany.com', 'thehill.com',
  'axios.com', 'insider.com', 'dailykos.com', 'thinkprogress.org',
  'mediamatters.org', 'commonwealthfund.org', 'americanprogress.org',
  'thedailybeast.com', 'theintercept.com', 'motherjones.com',
  'newrepublic.com', 'talkingpointsmemo.com', 'democracynow.org',
  'alternet.org', 'prospect.org', 'inthesetimes.com'
];

export const CENTER = [
  'reuters.com', 'apnews.com', 'bloomberg.com', 'usatoday.com',
  'marketwatch.com', 'fortune.com', 'businessinsider.com', 'axios.com',
  'thehill.com', 'upi.com', 'c-span.org', 'realclearpolitics.com',
  'npr.org', 'pbs.org', 'cfr.org', 'foreignaffairs.com', 'csmonitor.com',
  'law.com', 'statesman.com', 'scientificamerican.com', 'smithsonianmag.com',
  'governing.com', 'factcheck.org', 'snopes.com', 'politifact.com',
  'opensecrets.org', 'ballotpedia.org', 'govtrack.us', 'rollcall.com',
  'cqpolitics.com', 'defenseone.com', 'nextgov.com', 'federaltimes.com',
  'gcn.com', 'routefifty.com', 'statesmanjournal.com'
];

export const CENTER_RIGHT = [
  'wsj.com', 'forbes.com', 'reason.com', 'nationalreview.com',
  'washingtonexaminer.com', 'washingtontimes.com', 'nypost.com',
  'theamericanconservative.com', 'realclearinvestigations.com', 'freebeacon.com',
  'commentary.org', 'weeklystandard.com', 'nationalaffairs.com',
  'city-journal.org', 'commentarymagazine.com', 'nationalinterest.org',
  'hoover.org', 'aei.org', 'manhattan-institute.org', 'heritage.org',
  'cato.org', 'mises.org', 'thefederalist.com', 'spectator.org',
  'americanmind.org', 'lawliberty.org', 'claremont.org', 'firstthings.com',
  'americanconsequences.com', 'thebulwark.com', 'thedispatch.com',
  'washingtonmonthly.com', 'thecritic.co.uk', 'quillette.com'
];

export const FAR_RIGHT = [
  'foxnews.com', 'dailycaller.com', 'theblaze.com', 'newsmax.com',
  'breitbart.com', 'oann.com', 'townhall.com', 'theepochtimes.com', 'wnd.com',
  'americanthinker.com', 'lifezette.com', 'pjmedia.com', 'redstate.com',
  'twitchy.com', 'westernjournal.com', 'thegatewaypundit.com',
  'conservativereview.com', 'frontpagemag.com', 'humanevents.com', 'newsbusters.org',
  'cnsnews.com', 'americangreatness.com', 'dailywire.com',
  'thepostmillennial.com', 'thenationalpulse.com', 'justthenews.com',
  'thefederalistpapers.org', 'conservativebrief.com', 'bizpacreview.com',
  'theconservativetreehouse.com', 'americanactionnews.com',
  'conservativefighters.com', 'rightjournalism.com', 'conservativefiringline.com',
  'rightwingnews.com', 'conservativebase.com', 'rightsidenews.com',
  'conservativeangle.com', 'rightwingtribune.com', 'conservativeus.com'
];

// Generate BIAS_DATABASE automatically from the source arrays
export const BIAS_DATABASE = {
  ...Object.fromEntries(FAR_LEFT.map(domain => [domain, 1])),
  ...Object.fromEntries(CENTER_LEFT.map(domain => [domain, 30])),
  ...Object.fromEntries(CENTER.map(domain => [domain, 50])),
  ...Object.fromEntries(CENTER_RIGHT.map(domain => [domain, 75])),
  ...Object.fromEntries(FAR_RIGHT.map(domain => [domain, 100]))
};

// Export combined sources for bias calculations
export const BIAS_SOURCES = [FAR_LEFT, CENTER_LEFT, CENTER, CENTER_RIGHT, FAR_RIGHT];

// Keep existing AGGREGATORS and RSS_FEEDS as they were
export const AGGREGATORS = [
  'news.google.com',
  'news.bing.com',
  'news.yahoo.com',
  'msn.com',
  'aol.com'
];

export const RSS_FEEDS = [
  { url: 'https://news.google.com/rss', name: 'Google News' },
  { url: 'https://rss.nytimes.com/services/xml/rss/nyt/World.xml', name: 'New York Times' },
  { url: 'https://www.reutersagency.com/feed/?taxonomy=best-topics&post_type=best', name: 'Reuters' },
  { url: 'https://feeds.npr.org/1004/rss.xml', name: 'NPR' },
  { url: 'https://www.csmonitor.com/rss/world.rss', name: 'Christian Science Monitor' },
  { url: 'https://www.voanews.com/api/zyq_yteqpt', name: 'Voice of America' },
  { url: 'https://feeds.washingtonpost.com/rss/world', name: 'Washington Post' },
  { url: 'https://www.wsj.com/xml/rss/3_7085.xml', name: 'Wall Street Journal' },
  { url: 'https://www.foxnews.com/world.rss', name: 'Fox News' },
  { url: 'https://abcnews.go.com/abcnews/internationalheadlines', name: 'ABC News' },
  { url: 'https://www.nbcnews.com/id/3032091/device/rss/rss.xml', name: 'NBC News' },
  { url: 'https://www.cbsnews.com/latest/rss/world', name: 'CBS News' }
];

export const PRIMARY_SOURCES = [
  'apnews.com',
  'reuters.com', 
  'nytimes.com',
  'washingtonpost.com',
  'wsj.com',
  'cnn.com',
  'nbcnews.com',
  'abcnews.go.com',
  'cbsnews.com'
]; 