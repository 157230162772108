const countryMap = {
  'us': 'United States',
  'uk': 'United Kingdom',
  'ca': 'Canada',
  'au': 'Australia',
  'nz': 'New Zealand',
  'in': 'India',
  'za': 'South Africa',
  'ie': 'Ireland',
  // Add more country mappings as needed
};

const domainCountryMap = {
  'com': 'us',
  'co.uk': 'uk',
  'co.nz': 'nz',
  'com.au': 'au',
  // Add more domain-country mappings as needed
};

export function getCountryCode(article) {
  if (!article.url) return 'unknown';

  const url = new URL(article.url);
  const domain = url.hostname.toLowerCase();

  if (domain.endsWith('.uk')) return 'gb';
  if (domain.endsWith('.au')) return 'au';
  if (domain.endsWith('.ca')) return 'ca';
  if (domain.endsWith('.nz')) return 'nz';
  if (domain.endsWith('.in')) return 'in';
  if (domain.endsWith('.ie')) return 'ie';
  if (domain.includes('bbc')) return 'gb';
  if (domain.includes('nytimes') || domain.includes('washingtonpost')) return 'us';
  
  return 'us'; // Default to US for .com and other domains
}

export function getFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}
