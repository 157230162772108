import { fetchFlashOfTheDay } from './newsUtils';
import { getCachedFlashOfTheDay, setCachedFlashOfTheDay } from './cacheUtils';

export async function getFlashOfTheDay(edition) {
  try {
    console.log('Fetching fresh Flash of the Day for edition:', edition);
    const flashOfTheDay = await fetchFlashOfTheDay(edition);
    if (flashOfTheDay) {
      console.log('Received Flash of the Day:', flashOfTheDay);
      setCachedFlashOfTheDay(flashOfTheDay, edition);
    } else {
      console.error('No valid Flash of the Day found');
    }

    return flashOfTheDay;
  } catch (error) {
    console.error('Error in getFlashOfTheDay:', error);
    return null;
  }
}
