import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Link } from '@mui/material';
import { fetchHeadlines } from '../utils/newsUtils';
import { useTheme } from '@mui/material/styles';
import LoadingSpinner from './common/LoadingSpinner';
import { useEdition } from '../context/EditionContext';

const HeadlinesColumn = ({ refreshKey, bias }) => {
  const { currentEdition } = useEdition();
  const [headlines, setHeadlines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const getHeadlines = async () => {
      setLoading(true);
      try {
        const fetchedHeadlines = await fetchHeadlines(bias, currentEdition);
        setHeadlines(fetchedHeadlines);
        setError(null);
      } catch (err) {
        console.error('Error fetching headlines:', err);
        setError('Failed to fetch headlines. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    getHeadlines();
  }, [refreshKey, bias, currentEdition]);

  if (loading) return <LoadingSpinner />

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Box sx={{ 
        mb: 2,
        p: { xs: 0.5, sm: 1 },
        background: theme => theme.palette.mode === 'dark'
          ? 'linear-gradient(to right, rgba(139, 0, 0, 0.95), rgba(255, 69, 0, 0.95))'
          : 'linear-gradient(to right, rgba(255, 69, 0, 0.95), rgba(255, 140, 0, 0.95))',
        color: 'white',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="subtitle2" sx={{ 
          fontWeight: 700,
          fontSize: { xs: '0.85rem', sm: '1rem' }
        }}>
          HEADLINES
        </Typography>
        <Typography variant="subtitle2" sx={{ 
          fontWeight: 700,
          fontSize: { xs: '0.85rem', sm: '1rem' }
        }}>
          {currentEdition === 'eu' ? 'European' : '🇺🇸 US'} Edition
        </Typography>
      </Box>
      {headlines.map((article, index) => (
        <Card 
          key={index} 
          sx={{ 
            mb: 2, 
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            '&:hover': {
              boxShadow: theme.shadows[4],
              transform: 'translateY(-2px)',
              transition: 'all 0.3s ease-in-out'
            }
          }}
        >
          <CardContent>
            <Link 
              href={article.url} 
              target="_blank" 
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 'bold',
                  color: theme.palette.text.primary
                }}
              >
                {article.title}
              </Typography>
            </Link>
            <Typography 
              variant="body2" 
              sx={{ 
                position: 'absolute', 
                bottom: 8, 
                right: 8,
                color: theme.palette.text.secondary
              }}
            >
              {article.source}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default HeadlinesColumn;
