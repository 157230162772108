import React from 'react';
import { Box, Typography, Slider, styled, useTheme } from '@mui/material';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4,
    background: 'linear-gradient(to right, red, blue)',
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    background: 'linear-gradient(to right, red, blue)',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 16,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

const marks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 25,
    label: '',
  },
  {
    value: 50,
    label: '',
  },
  {
    value: 75,
    label: '',
  },
  {
    value: 100,
    label: '',
  },
];

const DescriptionBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  margin: '10px auto 0',
  padding: '10px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  backgroundColor: theme.palette.background.paper,
}));

const BiasSlider = ({ value, onChange }) => {
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: 500, 
      margin: '0 auto',
      mb: 8
    }}>
      <Typography 
        id="bias-slider" 
        gutterBottom 
        sx={{ 
          textAlign: 'center', 
          fontWeight: 'bold',
          fontStyle: 'italic',
          mb: 2
        }}
      >
        BIAS SLIDER
      </Typography>
      <StyledSlider
        aria-label="Political Bias"
        value={value}
        onChange={handleChange}
        step={null}
        marks={marks}
        min={0}
        max={100}
        sx={{
          '& .MuiSlider-thumb': {
            height: { xs: 24, sm: 20 },
            width: { xs: 24, sm: 20 },
          },
          '& .MuiSlider-rail': {
            height: { xs: 8, sm: 4 },
          },
          py: { xs: 2, sm: 1 },
        }}
      />
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        mt: 1,
        px: 2 // Add horizontal padding
      }}>
        <Typography variant="body2">LEFT</Typography>
        <Typography variant="body2">RIGHT</Typography>
      </Box>
      <DescriptionBox>
        <Typography
          variant="caption"
          sx={{
            fontFamily: 'Courier, monospace',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: 1.2,
            color: theme.palette.text.primary,
          }}
        >
          Move the slider to adjust the political bias of news sources. 
          Left shows more liberal-leaning sources, center balances both sides, 
          and right shows more conservative-leaning sources.
        </Typography>
      </DescriptionBox>
    </Box>
  );
};

export default BiasSlider;
