import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import boltIcon from '../assets/images/BoltAlpha_128.png';
import EditionSelector from './EditionSelector';

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <Box 
      sx={{
        background: theme.palette.mode === 'dark'
          ? 'linear-gradient(to right, rgba(30, 60, 114, 0.95), rgba(42, 82, 152, 0.95))'
          : 'linear-gradient(to right, rgba(0, 198, 255, 0.95), rgba(0, 114, 255, 0.95))',
        backdropFilter: 'blur(8px)',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: { xs: 'flex', md: 'grid' },
        flexDirection: { xs: 'column', md: 'unset' },
        gridTemplateColumns: { md: '1fr auto 1fr' },
        alignItems: 'center',
        padding: { xs: '0.5rem 1rem', md: '0.75rem 2rem' },
        zIndex: 1000,
      }}
    >
      {/* Date and Edition row (mobile) / Date (desktop) */}
      <Box sx={{ 
        display: { xs: 'flex', md: 'block' },
        width: { xs: '100%', md: 'auto' },
        justifyContent: 'space-between',
        alignItems: 'center',
        order: { xs: 2, md: 'unset' },
        mt: { xs: 1, md: 0 }
      }}>
        <Typography sx={{ 
          color: 'rgba(255,255,255,0.8)',
          fontSize: '0.875rem',
          fontWeight: 700,
        }}>
          {formattedDate}
        </Typography>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <EditionSelector />
        </Box>
      </Box>

      {/* Logo Section */}
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.25rem',
        order: { xs: 1, md: 'unset' }
      }}>
        {/* Logo */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Typography 
            variant="h2" 
            component="div" 
            sx={{ 
              fontWeight: 800,
              color: 'white',
              fontStyle: 'italic',
              textShadow: '0 2px 4px rgba(0,0,0,0.15)',
              fontSize: { xs: '1.5rem', md: '2rem' },
              lineHeight: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            FLASH
          </Typography>
          <img 
            src={boltIcon} 
            alt="Bolt" 
            style={{ 
              height: '2rem',
              width: 'auto',
              filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))',
              marginLeft: '0.6rem',
              marginRight: '0.1rem',
              transform: 'translateY(-1px)',
              display: 'block'
            }} 
          />
          <Typography 
            variant="h2" 
            component="div" 
            sx={{ 
              fontWeight: 800,
              color: 'white',
              fontStyle: 'italic',
              textShadow: '0 2px 4px rgba(0,0,0,0.15)',
              fontSize: { xs: '1.5rem', md: '2rem' },
              lineHeight: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            TOPICS
          </Typography>
        </Box>

        {/* Tagline */}
        <Typography 
          sx={{ 
            display: { xs: 'none', md: 'block' },
            color: 'rgba(255,255,255,0.9)',
            fontWeight: 600,
            fontSize: '0.75rem',
            letterSpacing: '0.05em',
            textAlign: 'center'
          }}
        >
          HAVE IT YOUR WAY
        </Typography>
      </Box>

      {/* Edition Selector (desktop only) */}
      <Box sx={{ 
        justifySelf: 'end',
        display: { xs: 'none', md: 'block' }
      }}>
        <EditionSelector />
      </Box>
    </Box>
  );
}

export default Header;
