import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Fade } from '@mui/material';

function AdBlockDetector() {
  const [isAdBlockEnabled, setIsAdBlockEnabled] = useState(false);

  useEffect(() => {
    const detectAdBlock = async () => {
      try {
        // Try to load Google AdSense script
        const script = document.createElement('script');
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.async = true;
        document.head.appendChild(script);

        // Wait for script to load or fail
        await new Promise((resolve, reject) => {
          script.onload = () => {
            setIsAdBlockEnabled(false);
            resolve();
          };
          script.onerror = () => {
            setIsAdBlockEnabled(true);
            reject();
          };
          // Timeout after 2 seconds
          setTimeout(resolve, 2000);
        });

        // Check if window.adsbygoogle exists
        if (typeof window.adsbygoogle === 'undefined') {
          setIsAdBlockEnabled(true);
        }

      } catch (error) {
        console.error('Ad block detection error:', error);
        setIsAdBlockEnabled(true);
      }
    };

    detectAdBlock();
  }, []);

  const handleDisableClick = () => {
    window.location.reload();
  };

  if (!isAdBlockEnabled) return null;

  return (
    <Fade in={isAdBlockEnabled}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(0, 0, 0, 0.95)',
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: 'white',
            mb: 3,
            fontWeight: 'bold'
          }}
        >
          Please Disable Your Ad Blocker
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'white',
            mb: 4,
            maxWidth: 600
          }}
        >
          We've detected that you're using an ad blocker. This website relies on ad revenue to provide free content, and we appreciate your support.
          Please disable your ad blocker to continue.
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={handleDisableClick}
          sx={{
            bgcolor: 'primary.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'primary.dark'
            }
          }}
        >
          I've Disabled My Ad Blocker
        </Button>
      </Box>
    </Fade>
  );
}

export default AdBlockDetector; 