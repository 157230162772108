import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Link, CircularProgress, Fade } from '@mui/material';
import { fetchNews } from '../utils/newsUtils';
import { getCountryCode, getFlagEmoji } from '../utils/countryUtils';
import { useEdition } from '../context/EditionContext';
import { useTheme } from '@mui/material/styles';

function NewsColumn({ category, bias, refreshKey }) {
  const { currentEdition } = useEdition();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    console.log('🔄 NewsColumn useEffect triggered:', {
      category,
      bias,
      currentEdition,
      refreshKey
    });

    const fetchArticles = async () => {
      setLoading(true);
      try {
        const fetchedArticles = await fetchNews(category, bias, currentEdition);
        setArticles(fetchedArticles);
        setError(null);
      } catch (err) {
        console.error('Error fetching articles:', err);
        setError('Failed to fetch articles. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [category, bias, refreshKey, currentEdition]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (articles.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>No articles found for this category and bias. Try adjusting the bias slider.</Typography>
      </Box>
    );
  }

  const renderArticle = (article, index) => {
    const countryCode = getCountryCode(article);
    const flagEmoji = getFlagEmoji(countryCode);

    console.log('Article title before rendering:', article.title);

    return (
      <Fade in={true} timeout={500} key={index}>
        <Card sx={{ 
          mb: 2, 
          overflow: 'hidden',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: (theme) => theme.shadows[10],
          }
        }}>
          <CardContent sx={{ py: 1.5, px: 2, position: 'relative' }}>
            <Link 
              href={article.url} 
              target="_blank" 
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
            >
              <Typography 
                variant="subtitle1" 
                component="div"
                sx={{ 
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                  transition: 'color 0.3s ease-in-out',
                  '&:hover': {
                    color: 'primary.main',
                  }
                }}
              >
                {flagEmoji} {article.title} {/* Display the title as is */}
              </Typography>
            </Link>
            <Typography 
              variant="caption" 
              sx={{ 
                position: 'absolute', 
                bottom: 4, 
                right: 8, 
                color: 'text.secondary'
              }}
            >
              {article.domain}
            </Typography>
          </CardContent>
        </Card>
      </Fade>
    );
  };

  const getColumnTitle = () => {
    switch(category) {
      case 'politics':
        return 'POLITICS';
      case 'business-tech':
        return 'BUSINESS & TECH';
      case 'entertainment-sports':
        return 'ENTERTAINMENT';
      default:
        return category.toUpperCase();
    }
  };

  return (
    <Box>
      <Box sx={{ 
        mb: 2,
        p: { xs: 0.5, sm: 1 },
        background: theme => theme.palette.mode === 'dark'
          ? 'linear-gradient(to right, rgba(30, 60, 114, 0.95), rgba(42, 82, 152, 0.95))'
          : 'linear-gradient(to right, rgba(0, 198, 255, 0.95), rgba(0, 114, 255, 0.95))',
        color: 'white',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="subtitle2" sx={{ 
          fontWeight: 700,
          fontSize: { xs: '0.85rem', sm: '1rem' }
        }}>
          {getColumnTitle()}
        </Typography>
        <Typography variant="subtitle2" sx={{ 
          fontWeight: 700,
          fontSize: { xs: '0.85rem', sm: '1rem' }
        }}>
          {currentEdition === 'eu' ? 'European' : '🇺🇸 US'} Edition
        </Typography>
      </Box>
      {articles.map(renderArticle)}
    </Box>
  );
}

export default NewsColumn;
