import { Box, CircularProgress } from '@mui/material'

function LoadingSpinner({ minHeight = '200px' }) {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      minHeight,
      marginTop: { xs: '64px', md: '80px' }
    }}>
      <CircularProgress />
    </Box>
  )
}

export default LoadingSpinner 