import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const EDITIONS = {
  us: {
    id: 'us',
    name: 'United States',
    label: 'US Edition',
    flag: '🇺🇸'
  },
  eu: { 
    id: 'eu',
    name: 'European Union',
    label: 'EU Edition',
    flag: '🇪🇺'
  }
};

const initialContextValue = {
  currentEdition: 'us',
  setEdition: (edition) => {}
};

const EditionContext = createContext(initialContextValue);

export function EditionProvider({ children }) {
  const [currentEdition, setCurrentEdition] = useState(() => {
    const saved = localStorage.getItem('preferredEdition');
    console.log('EditionProvider: Initial edition:', saved || 'us');
    return saved && EDITIONS[saved] ? saved : 'us';
  });

  const setEdition = (edition) => {
    console.log('EditionProvider: Setting edition to:', edition);
    if (EDITIONS[edition]) {
      setCurrentEdition(edition);
      localStorage.setItem('preferredEdition', edition);
    }
  };

  return (
    <EditionContext.Provider value={{
      currentEdition,
      setEdition
    }}>
      {children}
    </EditionContext.Provider>
  );
}

EditionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function useEdition() {
  const context = useContext(EditionContext);
  if (!context) {
    throw new Error('useEdition must be used within an EditionProvider');
  }
  return context;
} 