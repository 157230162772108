import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import packageJson from '../../package.json';

function Footer() {
  const theme = useTheme();

  const links = [
    { text: "CONTACT US", href: "mailto:info@11000ad.com" },
    { text: "PRIVACY POLICY", href: "/privacy-policy.html" },
    { text: "CALIFORNIA NOTICE", href: "/california-notice.html" },
    { text: "OPT-OUT CHOICES", href: "/opt-out-choices.html" },
    { text: "LEGAL", href: "/legal.html" },
    { text: "REFERENCE DESK", href: "https://www.refdesk.com/", external: true }
  ];

  return (
    <Box component="footer" sx={{ mt: 4, py: 2, textAlign: 'center' }}>
      <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <Link
              href={link.href}
              underline="hover"
              sx={{ 
                fontWeight: 'bold', 
                color: theme.palette.text.primary,
                ...(link.external && { target: "_blank", rel: "noopener noreferrer" })
              }}
            >
              {link.text}
            </Link>
            {index < links.length - 1 && (
              <Typography 
                component="span" 
                sx={{ 
                  mx: 1, 
                  color: theme.palette.text.secondary,
                  userSelect: 'none'  // Prevents selection of the divider
                }}
              >
                |
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Typography>
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block',
          mt: 2,
          color: theme.palette.text.secondary,
          fontWeight: 'bold'
        }}
      >
        FLASHTOPICS.COM v{packageJson.version}
      </Typography>
    </Box>
  );
}

export default Footer;
