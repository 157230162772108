import React from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useEdition } from '../context/EditionContext';
import { EDITIONS } from '../context/EditionContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function EditionSelector() {
  const { currentEdition, setEdition } = useEdition();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditionSelect = (editionId) => {
    console.log('🚨 EDITION SWITCH ATTEMPTED 🚨:', editionId);
    setEdition(editionId);
    handleClose();
  };

  const getEditionLabel = (edition) => {
    switch (edition) {
      case 'us':
        return '🇺🇸 US Edition';
      case 'eu':
        return 'European Edition';
      default:
        return 'US Edition';
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: 'inherit' }}
      >
        {getEditionLabel(currentEdition)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEditionSelect('us')}>
          US Edition
        </MenuItem>
        <MenuItem onClick={() => handleEditionSelect('eu')}>
          European Edition
        </MenuItem>
      </Menu>
    </>
  );
}

export default EditionSelector;