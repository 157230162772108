const CACHE_KEY = 'flashOfTheDay';

function encrypt(text) {
  return encodeURIComponent(text);
}

function decrypt(text) {
  return decodeURIComponent(text);
}

export function getCachedFlashOfTheDay() {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (!cachedData) return null;

  try {
    const decryptedContent = decrypt(cachedData);
    const { timestamp, article } = JSON.parse(decryptedContent);

    if (Date.now() - timestamp > 4 * 60 * 60 * 1000) {
      localStorage.removeItem(CACHE_KEY); // Cache is older than 4 hours
      return null;
    }

    return article;
  } catch (error) {
    console.error('Error parsing cached data:', error);
    localStorage.removeItem(CACHE_KEY);
    return null;
  }
}

export function setCachedFlashOfTheDay(article) {
  try {
    const content = JSON.stringify({
      timestamp: Date.now(),
      article
    });

    const encryptedContent = encrypt(content);
    localStorage.setItem(CACHE_KEY, encryptedContent);
  } catch (error) {
    console.error('Error caching Flash of the Day:', error);
  }
}
