// Major European news sources with English editions
export const FAR_LEFT = [
  'theguardian.com',
  'independent.co.uk',
  'morningstaronline.co.uk',
  'tribunemag.co.uk',
  'newstatesman.com',
  'thecanary.co',
  'bylinetimes.com',
  'opendemocracy.net',
  'jacobinmag.com/europe',
  'novayagazeta.eu',
  'mediapart.fr/en',
  'taz.de/en',
  'liberation.fr/en',
  'humanite.fr/en',
  'ilmanifesto.it/en',
  'publico.es/en'
];

export const CENTER_LEFT = [
  'bbc.co.uk',
  'bbc.com',
  'reuters.com',
  'inews.co.uk',
  'france24.com/en',
  'dw.com/en',
  'svt.se/news',
  'yle.fi/news',
  'nrk.no/news',
  'dr.dk/news',
  'nos.nl/en',
  'vrt.be/en',
  'rtbf.be/en',
  'rts.ch/en',
  'orf.at/news/en',
  'euronews.com',
  'politico.eu',
  'euobserver.com',
  'euractiv.com',
  'irishtimes.com',
  'thejournal.ie',
  'dutchnews.nl',
  'swissinfo.ch/eng',
  'thelocal.eu'
];

export const CENTER = [
  'reuters.com/uk',
  'news.sky.com',
  'brusselstimes.com',
  'euractivs.com',
  'neweurope.eu',
  'emerging-europe.com',
  'intellinews.com',
  'helsinkitimes.fi',
  'portugalresident.com',
  'romaniainsider.com',
  'praguemorning.cz',
  'budapesttimes.hu',
  'warsawvoice.pl',
  'balkaninsight.com',
  'total-croatia-news.com',
  'sloveniatimes.com',
  'greecenews.com',
  'bloomberg.com/europe',
  'marketwatch.com/europe',
  'investing.com/news/european-market-news',
  'europe.autonews.com',
  'energymonitor.ai/region/europe',
  'cityam.com'
];

export const CENTER_RIGHT = [
  'telegraph.co.uk',
  'thetimes.co.uk',
  'ft.com',
  'spectator.co.uk',
  'reaction.life',
  'handelsblatt.com/english',
  'welt.de/english',
  'faz.net/english',
  'corriere.it/english',
  'repubblica.it/english',
  'elmundo.es/english',
  'abc.es/english',
  'kathimerini.gr/english',
  'ekathimerini.com',
  'elpais.com/english',
  'nrc.nl/en',
  'standaard.be/english',
  'berlingske.dk/english',
  'aftenposten.no/english',
  'svd.se/english'
];

export const FAR_RIGHT = [
  'dailymail.co.uk',
  'express.co.uk',
  'thesun.co.uk',
  'standard.co.uk',
  'breitbart.com/europe',
  'remix.news',
  'visegradpost.com',
  'voiceofeurope.com',
  'unser-mitteleuropa.com/en',
  'valeursactuelles.com/en',
  'jungefreiheit.de/english',
  'friatider.se/english'
];

export const PRIMARY_SOURCES = [
  'reuters.com',
  'bbc.com',
  'france24.com/en',
  'dw.com/en',
  'euronews.com',
  'politico.eu',
  'ft.com',
  'bloomberg.com/europe',
  'apnews.com/hub/europe'
];

// Create BIAS_DATABASE
export const BIAS_DATABASE = {
  ...Object.fromEntries(FAR_LEFT.map(domain => [domain, 0])),
  ...Object.fromEntries(CENTER_LEFT.map(domain => [domain, 25])),
  ...Object.fromEntries(CENTER.map(domain => [domain, 50])),
  ...Object.fromEntries(CENTER_RIGHT.map(domain => [domain, 75])),
  ...Object.fromEntries(FAR_RIGHT.map(domain => [domain, 100]))
};

export const BIAS_SOURCES = [FAR_LEFT, CENTER_LEFT, CENTER, CENTER_RIGHT, FAR_RIGHT];

// RSS feeds for major European news sources
export const RSS_FEEDS = [
  { url: 'https://www.politico.eu/feed/', name: 'POLITICO Europe' },
  { url: 'https://www.euronews.com/rss', name: 'Euronews' },
  { url: 'https://www.dw.com/en/rss/news/en.xml', name: 'Deutsche Welle' },
  { url: 'https://www.france24.com/en/rss', name: 'France 24' },
  { url: 'https://feeds.bbci.co.uk/news/world/europe/rss.xml', name: 'BBC Europe' },
  { url: 'https://www.svt.se/rss.xml', name: 'SVT News' },
  { url: 'https://feeds.nos.nl/nosnieuwsalgemeen', name: 'NOS News' }
];